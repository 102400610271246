
import {
  onUnmounted,
  onMounted,
  defineComponent,
  inject,
  reactive,
  ref,
  watch
} from "vue";
import { PageParams } from "@/apis/interface/PageParams";
import { RespBody, RespBodyPager } from "@/apis/interface/Resp";
import {
  IServiceActivity,
  ServiceActivityFilter,
  ServiceActivityModel
} from "@/apis/interface/IServiceActivity";
import bus from "@/utils/bus";
import { useStore } from "@/store/index";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "List",
  components: {},
  setup() {
    const {
      state: {
        authData: { admin }
      }
    } = useStore();
    const apiServiceActivity = inject("apiServiceActivity") as IServiceActivity;

    const pageParams: PageParams = reactive({
      aaIndex: 1,
      aaSize: 50
    });

    const filter: ServiceActivityFilter = reactive({
      serviceBeanId: null,
      title: "",
      content: "",
      actAddress: "",
      actTimeFrom: null,
      actTimeTo: null
    });

    let clearFilter = () => {
      filter.serviceBeanId = null;
      filter.title = "";
      filter.content = "";
      filter.actAddress = "";
      filter.actTimeFrom = null;
      filter.actTimeTo = null;
      pageParams.aaIndex = 1;
      loadData();
    };

    let dataList = ref<ServiceActivityModel[]>([]);

    let companyId = ref(null);
    let loading = ref(false);

    onUnmounted(() => {
      bus.off("on-table-row-edit-ok");
    });

    let loadData = async () => {
      loading.value = true;
      const resp = await apiServiceActivity.getList(
        companyId.value || 0,
        pageParams,
        filter
      );
      const pager = resp as RespBodyPager<ServiceActivityModel>;
      pageParams.aaSize = pager.object.pageSize;
      pageParams.aaIndex = pager.object.pageCurrent;
      dataList.value = pager.object.listData;
      pageParams.total = pager.object.totalCount;
      // console.log("==>", dataList);
      loading.value = false;
    };

    onMounted(() => {
      loadData();
      bus.on("on-table-row-edit-ok", () => {
        loadData();
      });
    });

    let clickCreate = () => {
      bus.emit("on-table-row-edit", {
        dataList: [],
        dataRow: new ServiceActivityModel({
          serviceBeanId: filter.serviceBeanId,
          creatorAdminId: admin?.id
        })
      });
    };

    let statusFormat = (status: number) => {
      // console.log(status);
      return "正常";
    };

    let selectTimeRange = (arr: Array<any>) => {
      filter.actTimeFrom = arr[0];
      filter.actTimeFrom = arr[1];
    };

    let deleteRow = async (id: number) => {
      let result = (await apiServiceActivity.remove([id])) as RespBody<number>;
      if (result.object > 0) {
        ElMessage({
          type: "success",
          message: "刪除成功！"
        });
        await loadData();
      } else
        ElMessage({
          type: "error",
          message: "刪除失敗"
        });
    };

    watch(
      () => {
        return filter.serviceBeanId;
      },
      () => {
        pageParams.aaIndex = 1;
        dataList.value = [];
        loadData();
      }
    );
    watch(
      () => pageParams.aaSize,
      () => {
        pageParams.aaIndex = 1;
        loadData();
      }
    );
    watch(
      () => pageParams.aaIndex,
      () => {
        loadData();
      }
    );

    let tableHeight = ref(0);
    let timeRange = ref([null, null]);

    return {
      loading,
      tableHeight,
      pageParams,
      filter,
      companyId,
      dataList,
      statusFormat,
      clickCreate,
      selectTimeRange,
      loadData,
      timeRange,
      deleteRow,
      clearFilter
    };
  }
});
